import React, { useState } from 'react';
import { Header } from '@/components/Chat/Header';
import { AppShell, Group, Burger, Skeleton, NavLink, Divider, ActionIcon, Box, Button, Grid, Text, Stack, Paper, Avatar, Center, Tooltip } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import ChatContent from '@/components/Chat/ChatContent';
import ModelSelect from '@/components/Chat/ModelSelect';
import ChatBox from '@/components/Chat/UserInput';
import classes from './Home.module.css';
import { convertToOllamaMessages, supaBaseGetChatMessagesBySession, supaBaseGetSessions, supaBaseWriteToChatMessage, supaBaseWriteToChatSession } from '@/utility/Utilities';
import NavBarSessions from '@/components/NavBar/NavBarSessions';
import EmptyChat from '@/components/Chat/EmptyChat';
import NewChatButton from '@/components/NavBar/NewChatButton';
import powered from '../assets/powered.png';
import { ChatHeader } from '@/components/Chat/ChatHeader';
import LottieAnimation from '@/utility/LottieAnimation';
import SideBarIcon from '@/components/Icons/SideBarIcon';
import PencilSquareIcon from '@/components/Icons/PencilSquareIcon';
import MobileNav from '@/components/NavBar/MobileNav';



export function HomePage() {
  const [opened, { toggle }] = useDisclosure();
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [chatId, setChatId] = useState<string>("")
  const [loading, setLoading] = useState(false);
  const [modeLoading, setModelLoading] = useState(false);

  const [sessions, setSessions] = useState<ChatSession[]>([])
  const [sessionsLoading, setSessionsLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const messageQueue: (ChatMessage & { done: boolean })[] = [];
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [divHeight, setDivHeight] = useState(window.screen.height - 35);
  

  let processing = false;
  const fetchSessions = async () => {
    setSessionsLoading(true)
    const sessionsFromSupaBase = await supaBaseGetSessions();

    if (!sessionsFromSupaBase || !sessionsFromSupaBase?.length) {
      throw new Error('Error retrieving sessions');
    }


    const mappedSessions: ChatSession[] = sessionsFromSupaBase.map(rawSession => ({
      id: rawSession.id,
      createdAt: rawSession.created_at,
      title: rawSession.title,
      lastModelUsed: rawSession.last_model_used,
      chatMessages: [] //unused?
    }));

    // Sort the messages by createdAt (ascending order)
    const sortedMappedSessions = mappedSessions.sort((a, b) => {
      return new Date(a.createdAt!).getTime() - new Date(b.createdAt!).getTime();
    });

    setSessions(sortedMappedSessions)
    setSessionsLoading(false);
  }

  React.useEffect(() => {
    fetchSessions();
  }, []);

  React.useEffect(() => {
    const handleResize = () => {
      setDivHeight(window.innerHeight - 35);
    };

    // Update div height on window resize
    window.addEventListener('resize', handleResize);
    handleResize();

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  const summarizePrompt = async (prompt: string) => {
    try {
      const res = await fetch('https://culshohgngkqzgcalywa.supabase.co/functions/v1/chatgpt', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: 'llama3.1:70b',
          messages: [
            { role: 'user', content: `Summarize the following in 2-4 words: "${prompt}"` },
          ],
          stream: false,
        }),
      });

      if (!res.ok) {
        throw new Error('Failed to fetch response from Ollama');
      }

      const data = await res.json();

      if (data.choices && data.choices.length > 0) {
        const choice = data.choices[0];
        let choiceMessageBody = choice.message;
        if (choiceMessageBody.content) {
          let choiceMessage = choiceMessageBody.content;
          choiceMessage = choiceMessage.replace("<|eot_id|>", "");
          return choiceMessage.split('\n')[0].slice(0, 39).trim().replaceAll("\"", "");
        }

      }
      throw new Error('Failed to summarize prompt');

      // only return the first 40 characters
    } catch (error) {
      console.error('Error:', error);
      throw new Error('Failed to summarize prompt');
    }
  };

  const loadSessionFromServer = async (passedChatId: string) => {

    if (!passedChatId || passedChatId == "") return;

    const fetchMessages = async () => {
      try {
        const messagesFromSupaBase = await supaBaseGetChatMessagesBySession(passedChatId);

        if (!messagesFromSupaBase) {
          throw new Error("No messages found");
        }

        // Map the incoming data to your ChatMessage interface
        const chatMessages: ChatMessage[] = messagesFromSupaBase.map(message => ({
          id: message.id,
          createdAt: message.created_at,
          message: {
            role: message.message.role,
            content: message.message.content,
          },
          chatSession: message.chat_session,
        }));

        // Sort the messages by createdAt (ascending order)
        const sortedChatMessages = chatMessages.sort((a, b) => {
          return new Date(a.createdAt!).getTime() - new Date(b.createdAt!).getTime();
        });

        setMessages(sortedChatMessages);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();

  }

  const handleInputFromUser = async (message: string) => {

    setModelLoading(true);

    let confirmedChatUuid = chatId

    if (!confirmedChatUuid || confirmedChatUuid === "") {
      setLoading(true);
      confirmedChatUuid = await createNewSessioninServer(message);
      setLoading(false);

    }


    if (!confirmedChatUuid || confirmedChatUuid === "") {
      throw new Error('No chat id found')
    }



    const quid = generateUUID();
    handleSendMessage(confirmedChatUuid, quid, { content: message, role: 'user' }, true);

    const history = convertToOllamaMessages(messages);
    history.push({ content: message, role: 'user' })



    const response = await fetch('https://culshohgngkqzgcalywa.supabase.co/functions/v1/chatgpt', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        model: 'llama3.1:70b',
        messages: history,
        stream: true, // Enables streaming
      }),

    });

    const reader = response.body?.getReader();
    const decoder = new TextDecoder('utf-8');
    const uuid = generateUUID();

    if (!reader) return;

    let done = false;

    while (!done) {
      const { value, done: readerDone } = await reader.read();
      done = readerDone;
      const chunk = decoder.decode(value, { stream: true });

      if (chunk.includes('\n')) {
        const lines = chunk.split('\n').filter(line => line.trim() !== '');
        for (let line of lines) {
          try {
            line = line.replace("data: ", "");
            line = line.trim();
            const json = JSON.parse(line);

            if (json.choices && json.choices !== "undefined" && json.choices.length > 0) {

              const choicesJson = json.choices[0];

              if (choicesJson.delta) {
                const delta = choicesJson.delta;


                const content: string = delta.content ?? "";

                if (content === "<|eot_id|>") {
                  continue
                }

                handleSendMessage(confirmedChatUuid, uuid, { role: 'assistant', content }, choicesJson.finish_reason);


              }

            }
          } catch (e) {
            // console.error('Failed to parse JSON:', e, line);
          }
        }
      } else {
        try {
          let line = chunk.replace("data: ", "");
          line = line.trim();
          const json = JSON.parse(line);

          if (json.choices && json.choices !== "undefined" && json.choices.length > 0) {

            const choicesJson = json.choices[0];

            if (choicesJson.delta) {
              const delta = choicesJson.delta;


              if (delta.content) {
                const content: string = delta.content;

                if (content === "<|eot_id|>") {
                  continue
                }


                handleSendMessage(confirmedChatUuid, uuid, { role: 'assistant', content }, choicesJson.finish_reason);
              }

            }

          }
        } catch (e) {
          // console.error('Failed to parse JSON:', e, chunk);
        }
      }
    }

    setModelLoading(false)


  };

  function sendToServer(updatedMessage: ChatMessage) {
    supaBaseWriteToChatMessage(updatedMessage);
  }

  const createNewSessioninServer = async (firstMessage: string) => {
    const uuid = generateUUID()
    setLoading(true);

    const title = await summarizePrompt(firstMessage);

    const newSession: ChatSession = {
      id: uuid as string,
      title,
      lastModelUsed: "93ab126a-33cf-4bd8-8832-80aa0102c92c", // TODO 
    }

    await supaBaseWriteToChatSession(newSession);
    fetchSessions();
    setLoading(false);
    setChatId(uuid);
    return uuid;
  }

  /*handles the entire process of a message being sent to the server*/
  const handleSendMessage = async (confirmedChatUuid: string, chatUUID: string, incomingToken: ChatMessageMessage, done: boolean) => {

    if (!confirmedChatUuid) return

    // Add the message to the queue
    messageQueue.push({ id: chatUUID, message: incomingToken, chatSession: confirmedChatUuid, done });

    // Start processing the queue if not already processing
    if (!processing) {
      processQueue(confirmedChatUuid);
    }

  };

  const loadChat = (session: ChatSession) => {
    setChatId(session.id);
    loadSessionFromServer(session.id);
  }

  const setNewChat = () => { //todo
    setLoading(true);
    setMessages([]);
    setChatId("");
    //in 1 seconds setLoading False
    setTimeout(() => { setLoading(false); }, 1000);
  }


  /* this could certainly be cleaned up a bit */
  const processQueue = (confirmedChatUuid: string) => {
    if (messageQueue?.length === 0) {
      processing = false;
      return;
    }


    processing = true;

    // Get the first message from the queue
    if (messageQueue) {
      const messageItem = messageQueue.shift();
      if (messageItem) {
        // Process the message
        setMessages(messages => {
          const existingMessageIndex = messages.findIndex(message => message.id === messageItem.id);

          if (existingMessageIndex !== -1) {
            // Create a new array with the updated message
            const updatedMessages = [...messages];
            // confusing but create a new message wrapper with the same data, just update the content.
            const newMessage = {
              ...updatedMessages[existingMessageIndex],
              message: {
                ...updatedMessages[existingMessageIndex].message,
                content: updatedMessages[existingMessageIndex].message.content + messageItem.message.content
              }
            };
            updatedMessages[existingMessageIndex] = newMessage;
            if (messageItem.done) {

              sendToServer(newMessage)
            }
            return updatedMessages;
          } else {
            // Add the new message if it doesn't exist
            const newMessages = [...messages, { id: messageItem.id, chatSession: confirmedChatUuid, message: messageItem.message }];
            if (messageItem.done) {
              sendToServer(messageItem)
            }
            return newMessages;
          }
        });
      }
    }
    // Continue processing the next message in the queue
    setTimeout(processQueue, 0);
  };

  return (
    <AppShell
      header={{ height: 0 }}
      navbar={{ width: opened ? 64 : 300, breakpoint: 'sm', collapsed: { mobile: !opened } }}
      padding="md"
      styles={{
        main: {
          border: 'none', // Remove border from main content
        },
        header: {
          border: 'none', // Remove border from header
        },
        navbar: {
          border: 'none', // Remove border from navbar
        },
      }}
    >
      <AppShell.Header>
        <Group h="100%" px="md">
          <Burger mt={"20px"} opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
          {/* <Header /> */}
        </Group>
      </AppShell.Header>
      <AppShell.Navbar p={!opened ? 'md' : '0'} bg={opened ? 'white' : 'fnsidebarblue'}>
        <Box hidden={opened} style={{ display: 'flex', flexDirection: 'column', height: opened ? '0%' : '100%' }}>
          <Box style={{ height: opened ? '0px' : '140px', display: 'flex', flexDirection: 'column', justifyContent: 'center', }}>

            <Grid align="center">
              {/* First column with fixed width */}
              <Grid.Col hidden={opened} span="content">
                <Center>
                  <NewChatButton onClick={setNewChat} />
                </Center>
              </Grid.Col>

              {/* Second column takes the remaining space */}
              <Grid.Col hidden={opened} span="auto">
                <Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%' }}>
                  <Center>
                    <Tooltip label="Collapse Sidebar" color="black">
                      <SideBarIcon onClick={toggle} />
                    </Tooltip>
                  </Center>
                </Box>
              </Grid.Col>

            </Grid>

            <Text hidden={opened} fw={600} size={'16px'} mt={36} mb={24}> Chat History </Text>
          </Box>

          <Box style={{ flexGrow: 1, overflowY: 'auto' }}>
            <NavBarSessions sessions={sessions} sessionsLoading={sessionsLoading} loadChat={loadChat} />
          </Box>
          <Box style={{ height: '90px', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
            <img style={{ maxWidth: '96px', display: opened ? 'none' : 'inline' }} src={powered} alt="powered by" ></img>
          </Box>
        </Box>
        <Box h={"100%"} hidden={!opened}>
          {isMobile
            ?
            <><MobileNav setNewChat={setNewChat} open={opened} toggle={toggle} sessions={sessions} sessionsLoading={sessionsLoading} loadChat={loadChat} /></>
            :
            <Stack w={'60px'} h={"100%"} justify='flex-start' align="center" mt={14} ml={4}>
              <Paper w={'60px'} radius={'md'} bg={'#F5F9FF'} pt={'md'} pb={'md'} ml="12">
                <Stack gap={'0px'} w={'60px'} justify='center' align="center">
                  {/* color='#242526'  */}
                  <Tooltip label="Expand Sidebar" position='right' color="black">
                    <SideBarIcon onClick={toggle} />
                  </Tooltip>
                  <Tooltip label="New Chat" position='right' color="black">

                    <PencilSquareIcon onClick={setNewChat} />
                  </Tooltip>

                </Stack>
              </Paper>
            </Stack>}

        </Box>
      </AppShell.Navbar>
      <AppShell.Main>
        <Stack style={{ height: `${divHeight}px` }}>
          <ChatHeader />
          {/* TODO CLEAN THIS UP */}
          {loading
            ? <>
              <div style={{ height: "100%" }}>
                <Center style={{ height: "100%" }}>
                  <LottieAnimation animation='square' style={{ width: '150px', height: "150px" }} />
                </Center>
              </div>

            </>
            :
            <>{messages?.length > 0
              ? <ChatContent messages={messages} loading={modeLoading} />
              : <EmptyChat handleSend={handleInputFromUser} />}

              <ChatBox handleSend={handleInputFromUser} /></>
          }

        </Stack>
      </AppShell.Main>
    </AppShell>
  );
}
