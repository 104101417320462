import { md5 } from 'js-md5';
import { supabase } from '../supabase';

export class UserService {
    private static instance: UserService;
    private user: any | null = null;
    private session: any | null = null;
    private fetchingUser: Promise<any> | null = null;
    private fetchingSession: Promise<any> | null = null;
    private avatar: { val: string; isUrl: boolean } | null = null;

    private constructor() {}

    // Get the singleton instance of the service
    public static getInstance(): UserService {
        if (!UserService.instance) {
            UserService.instance = new UserService();
        }
        return UserService.instance;
    }

    public async getSession(): Promise<any> {
        if (this.session) return Promise.resolve(this.session);
        if (this.fetchingSession) return this.fetchingSession;

        this.fetchingSession = supabase.auth.getSession()
            .then(({ data: { session }, error }) => {
                if (error) {
                    console.error('Error fetching session:', error);
                    return null;
                }
                this.session = session;
                return session;
            })
            .catch(err => {
                console.error('Unexpected error:', err);
                return null;
            })
            .finally(() => {
                this.fetchingSession = null;
            });

        return this.fetchingSession;
    }

    public async getUser(): Promise<any> {
        if (this.user) return Promise.resolve(this.user);
        if (this.fetchingUser) return this.fetchingUser;

        this.fetchingUser = supabase.auth.getUser()
            .then(async ({ data: { user }, error }) => {
                if (error) {
                    console.error('Error fetching user:', error);
                    return null;
                }
                this.user = user;
                if (user?.email) {
                    this.avatar = await this.getUserImage(user.email);
                }
                return user;
            })
            .catch(err => {
                console.error('Unexpected error:', err);
                return null;
            })
            .finally(() => {
                this.fetchingUser = null;
            });

        return this.fetchingUser;
    }

    public getAvatar(): { val: string; isUrl: boolean } | null {
        return this.avatar;
    }

    private getGravatarUrl(email: string): string {
        const hash = md5(email.trim().toLowerCase());
        return `https://www.gravatar.com/avatar/${hash}?d=404`;
    }

    private async imageExists(url: string): Promise<boolean> {
        try {
            const response = await fetch(url);
            return response.ok;
        } catch {
            return false;
        }
    }

    private getInitials(email: string): string {
        const [name] = email.split('@');
        return name.charAt(0).toUpperCase();
    }

    private async getUserImage(email: string): Promise<{ val: string; isUrl: boolean }> {
        const gravatarUrl = this.getGravatarUrl(email);

        if (await this.imageExists(gravatarUrl)) {
            return { val: gravatarUrl, isUrl: true };
        } else {
            return { val: this.getInitials(email), isUrl: false };
        }
    }
}
