
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { supabase } from "@/supabase";
import { Box, Center, Stack, Text } from "@mantine/core";
import checkMarkYes from "../assets/checkmarkyes.png"
import classes from "./VerificationEmailSent.module.css"
export function VerificationEmailSent() {


    return <Center style={{ height: '100vh' }}>
        <Box
            style={{
                width: 405,
                textAlign: 'center',
            }}
        >
            <Stack align="center">
                {/* First Row: CheckMark Icon */}
                <img src={checkMarkYes} alt=""/>

                {/* Second Row: Verification message */}
                <Text className={classes.mainText}>
                    Verification Email Sent!
                </Text>

                {/* Third Row: Instructional text */}
                <Text className={classes.subText}>
                    Please check your inbox to confirm
                </Text>
            </Stack>
        </Box>
    </Center>
}
