import { Grid, Modal, Group, Loader, Box, Center, Stack, TextInput, PasswordInput, Button } from "@mantine/core";

import classes from '../app.module.css';
import logo from '../assets/logonewchat.png';
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { supabase } from "@/supabase";
import { notifications } from "@mantine/notifications";
import { useDisclosure } from "@mantine/hooks";




export function ForgotPassword() {

    const [searchParams] = useSearchParams();
    // Get the access token from the URL
    const accessToken = searchParams.get('token');
    const navigate = useNavigate();
    const [opened, { open, close }] = useDisclosure(false);




    useEffect(() => {
        async function checkSession() {
            const { data, error } = await supabase.auth.getSession();
            if (error || !data.session) {
                console.error('No valid session found!', error);
                // Redirect the user to an error page if needed
            } else {
            }
        }

        checkSession();
    }, [accessToken]);


    const [loginState, setLoginState] = useState("")

    const [password, setPassword] = useState(""); // State for password
    const [password2, setPassword2] = useState(""); // State for password


    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        setPassword(event.target.value);

    const handlePasswordChange2 = (event: React.ChangeEvent<HTMLInputElement>) =>
        setPassword2(event.target.value);

    const handleSendPasswordChange = async () => {



        // setError(null);

        if (password != password2) {
            notifications.show({
                title: 'Error',
                color: 'red',
                message: 'Passwords do not match!',
                position: 'top-center'
            })
            return;
        }

        setLoginState("Loading...")
        open()

        try {
            const { error } = await supabase.auth.updateUser({
                password: password,
            });

            if (error) {
                close()
                notifications.show({
                    title: 'Error',
                    color: 'red',
                    message: 'Could not change password!',
                    position: 'top-center'
                })
            } else {
                // Password updated successfully, redirect to login or home page
                notifications.show({
                    title: 'Success',
                    color: 'green',
                    message: 'Password changed!',
                    position: 'top-center'
                })
                close()
                navigate('/login'); // Or any other page
            }
        } catch (err) {
            //   setError('Something went wrong, please try again.');\
            notifications.show({
                title: 'Error',
                color: 'red',
                message: 'Something went wrong!',
                position: 'top-center'
            })
        } finally {
            close();
            setLoginState("")
        }
    };



    return <Grid
        justify="center" // Centers horizontally
        align="start"     // Aligns to the top vertically
        gutter={0}
        style={{ height: '100vh' }} // Ensures the grid takes the full height of the screen
    >

        <Modal opened={opened} onClose={close} withCloseButton={false}>
            <Group justify="center">                    <Loader />
                <Box><Center>{loginState}</Center></Box>
            </Group>
        </Modal>
        <Grid.Col
            span={{ base: 12, md: 4, lg: 3, sm: 5, xs: 11.5 }}
        >
            <div >
                <div className={classes.logoContainer}>
                    <Stack justify="center" align="center">
                        <img
                            src={logo}
                            alt="Logo"
                            height={"40px"}
                            className={classes.logo}
                        />
                        <p className={classes.text}>Change Password</p>
                    </Stack>
                </div>

                <PasswordInput
                    label="New Password"
                    classNames={{ label: classes.label, input: classes.input }}
                    onKeyDown={(e) => e.key === "Enter" && handleSendPasswordChange()} // Handle enter key
                    onChange={handlePasswordChange}
                />

                <PasswordInput
                    label="Confirm Password"
                    classNames={{ label: classes.label, input: classes.input }}
                    onKeyDown={(e) => e.key === "Enter" && handleSendPasswordChange()} // Handle enter key
                    onChange={handlePasswordChange2}
                />


                <div>
                    <Button variant="unstyled" onClick={handleSendPasswordChange} className={classes.logInBtn}> Change Password </Button>
                </div>
            </div>

        </Grid.Col>
    </Grid>
}
