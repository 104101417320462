import { Button } from '@mantine/core';
import React from 'react';
import PencilSquareIcon from '../Icons/PencilSquareIcon';
import classes from './NewChatButton.module.css';


interface Props {
    onClick?: () => void;
}

const NewChatButton: React.FC<Props> = ({ onClick }) => {
    return (
        <>
                <Button variant={'unstyled'} className={classes.newchatbutton}  onClick={onClick} leftSection={<PencilSquareIcon iconOnly />} radius={'xl'}>
                    New Chat
                </Button>
        </>
    );
}

export default NewChatButton;
