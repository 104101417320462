import React, { useEffect } from 'react';
import '@mantine/core/styles.css';
import { MantineProvider } from '@mantine/core';
import { useState } from 'react';
import { Router } from './Router';
import { theme } from './theme';
import { createClient } from '@supabase/supabase-js'
import Login from './components/Login/Login';
import '@rainbow-me/rainbowkit/styles.css';
import '@mantine/notifications/styles.css';

import { connectorsForWallets, getDefaultConfig, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { createConfig, http, WagmiProvider } from 'wagmi';
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  base,
  baseSepolia,
  sepolia,
} from 'wagmi/chains';

import {
  QueryClientProvider,
  QueryClient,
} from "@tanstack/react-query";
import { Notifications } from '@mantine/notifications';
import { coinbaseWallet } from '@rainbow-me/rainbowkit/wallets';
coinbaseWallet.preference = 'eoaOnly';
// const config = getDefaultConfig({
//   appName: 'Function Network',
//   projectId: '895d9e1a47163f4e0ba225cbd8b87d80',
//   chains: [mainnet],
//   ssr: false, // If your dApp uses server side rendering (SSR)
// });

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [coinbaseWallet],
    },
  ],
  {
    appName: 'Function Network',
    projectId: '895d9e1a47163f4e0ba225cbd8b87d80',
  }
);


export const config = createConfig({
  chains: [mainnet],

  connectors,
  transports: {
    [mainnet.id]: http(),
  },
});



export interface SupabaseAuthSession {
  access_token: string;
  refresh_token?: string;
  user: {
    id: string;
    aud: string;
    role?: string;
    email?: string;
    phone?: string;
    created_at: string;
    confirmed_at?: string;
    last_sign_in_at?: string;
  };
  expires_in?: number;
  expires_at?: number;
  token_type?: string;
}

const supabase = createClient('https://culshohgngkqzgcalywa.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImN1bHNob2hnbmdrcXpnY2FseXdhIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjM1ODE1NjAsImV4cCI6MjAzOTE1NzU2MH0.kVE25kwBqBHTafmWZ7T0Sa-bmsX0dJQ35VI_Gpm8eZY')

const queryClient = new QueryClient();
export default function App() {
  const [session, setSession] = useState<SupabaseAuthSession | null>(null);


  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [])

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <MantineProvider theme={theme}>
            <Notifications />
            <Router session={session} />
          </MantineProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>

  );
}
