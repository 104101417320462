
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { supabase } from "@/supabase";

export function GoogleLandingPage() {

    const [searchParams] = useSearchParams();
    // Get the access token from the URL
    const accessToken = searchParams.get('token');
    const navigate = useNavigate();

    useEffect(() => {
        async function checkSession() {
            const { data, error } = await supabase.auth.getSession();
            if (error || !data.session) {
                console.error('No valid session found!', error);
                navigate('/login')
            } else {
                navigate('/')
            }
        }

        checkSession();
    }, [accessToken]);

    return <></>
}
