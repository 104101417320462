
import React from 'react';
import classes from './PaperSendIcon.module.css';


function PaperSendIcon({ ...rootDOMAttributes }) {
    return (
        <span {...rootDOMAttributes} className={classes.iconwrapper}>
            <svg width={20} height={20} preserveAspectRatio="xMidYMid meet"
                color='blue' rotate='19deg' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                <g transform="rotate(40, 12, 12)">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                </g>
            </svg>
        </span>

    );
}


export default PaperSendIcon;
