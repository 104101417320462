

import { Box } from '@mantine/core';
import React from 'react';

function ChevronDownIcon() {
    return (
        <Box w={17} h={17}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" width={17} height={17} preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
        </Box>

    );
}

export default ChevronDownIcon;
