

import { Box, Text } from '@mantine/core';
import logo from '../../assets/logonewchat.png';
import Markdown from 'react-markdown';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import classes from "./FunctionChatResponse.module.css"
interface props {
    message: string
}

const FunctionChatResponse: React.FC<props> = ({ message }) => {
    return (
        <Box
            mb={'38'}
            style={{
                display: 'flex',
                alignItems: 'stretch', // Ensure both columns stretch to the same height
            }}
        >
            {/* First column with the icon */}
            <Box
                style={{
                    minWidth: '26px',
                    maxWidth: '26px',
                    position: 'relative', // Enables absolute positioning of the icon
                    display: 'flex',
                    alignItems: 'flex-start', // Aligns icon to top
                    justifyContent: 'flex-end', // Aligns icon to the right  
                }}
            >
                <img
                    style={{
                        width: '100%', // Ensures image fits the 30px width
                        objectFit: 'contain',
                    }}
                    src={logo}
                    alt="Logo"
                />
            </Box>

            {/* Second column with the remaining width and gray background */}
            <Box
                p={'16px'}
                bg={'fngray'}
                ml={5}
                style={{
                    flexGrow: 1, // Makes this column take up the remaining width
                    padding: '10px',
                    borderTopLeftRadius: '0px',
                    borderTopRightRadius: '20px',
                    borderBottomLeftRadius: '20px',
                    borderBottomRightRadius: '20px',
                    width:"100%"
                }}
            >
                <div className={classes.markdownContainer}>
                    <Markdown
                        components={{
                            code(props) {
                                const { children, className } = props;
                                const match = /language-(\w+)/.exec(className || '');
                                return match ? (
                                    <SyntaxHighlighter
                                        PreTag="div"
                                        language={match[1]}
                                        style={dark}
                                    >
                                        {String(children).replace(/\n$/, '')}
                                    </SyntaxHighlighter>
                                ) : (
                                    <SyntaxHighlighter
                                        PreTag="div"
                                        style={dark}
                                    >
                                        {String(children).replace(/\n$/, '')}
                                    </SyntaxHighlighter>
                                );
                            },
                        }}
                    >
                        {message}
                    </Markdown>
                </div>
            </Box>
        </Box>
    );
}

export default FunctionChatResponse;